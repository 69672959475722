@import '~antd/dist/antd.css';

html,
body {
  background: #f0f2f5;
}

::-webkit-scrollbar {
  background: transparent;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a6a6a6;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  display: none;
}

.table-row-highlight {
  background-color: rgb(230, 247, 255);
}

.rubbish-bin-icon {
  display: none;
}

.shop-scene-intent-table-row:hover .rubbish-bin-icon {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  line-height: 1;
}
